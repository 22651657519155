@import "bootstrap-reboot";
@import "bootstrap/type";
@import "bootstrap/nav";

@mixin content {
  padding: 10px;
}

header {
  @include content();
  margin: 0 0 20px 0;
  border-bottom: $border-width solid $border-color; 
}

footer {
  @include content();
  border-top: $border-width solid $border-color; 
}

.main-content {
  @include content();
}

.title {
  @extend .h1;
}

.no-underline {
  text-decoration: none;
}